<template>
  <b-row>
    <b-col class="mass-messages">
      <div>
        <PageHeader :title="$t('general.chat.title')">
          <div>
            <button type="button" @click="toNext" class="base-button" :disabled="unlessSelect">
              <span class="base-button__font">
                {{ $t("general.massMessage.next") }}
              </span>
            </button>
          </div>
        </PageHeader>

        <div class="mass-messages__top">
          <div class="mass-messages__title">
            <span class="mass-messages__font mass-messages__font--title">
              {{ $t("general.massMessage.include") }}
            </span>
          </div>

          <div class="mass-messages__scroll-group">
            <CRadio
              v-model="form.include"
              v-for="(item, index) in transformedMessagesList"
              :key="`${index + '-included'}`"
              :name="item.name"
              :value="item"
              input-type="checkbox"
              group-name="include"
              allow-uncheck
              :disabled="!item.listees_count || item.excluded"
            >
              <template #default="{ isActive, value: group }">
                <div
                  class="message-group-card include-group__item"
                  :class="{
                    'include-group__item--active': isActive,
                    'message-group-card--disabled': !group.listees_count || item.excluded,
                  }"
                >
                  <div class="message-group-card__icon-wrapper">
                    <CIcon
                      name="select"
                      path="messages"
                      class="message-group-card__icon"
                      v-if="isActive"
                    />
                    <CIcon
                      name="circle"
                      path="messages"
                      class="message-group-card__icon"
                      v-else
                    />
                  </div>

                  <div class="message-group-card__description">
                    <span
                      class="message-group-card__font message-group-card__font--name"
                    >
                      {{ group.title }}
                    </span>

                    <span
                      class="message-group-card__font message-group-card__font--users"
                    >
                      {{
                        $tc(
                          "general.massMessage.usersN",
                          group.listees_count || 0,
                          {
                            amount: group.listees_count || 0,
                          }
                        )
                      }}
                    </span>
                  </div>
                </div>
              </template>
            </CRadio>
          </div>

          <div class="mass-messages__title mass-messages__title--second">
            <span class="mass-messages__font mass-messages__font--title">
              {{ $t("general.massMessage.doNotInclude") }}
            </span>
          </div>

          <div class="mass-messages__scroll-group">
            <CRadio
              v-model="form.exclude"
              v-for="(item, index) in transformedMessagesList"
              :key="`${index + '-included'}`"
              :name="item.name"
              :value="item"
              input-type="checkbox"
              group-name="exclude"
              allow-uncheck
              :disabled="!item.listees_count || item.included"
            >
              <template #default="{ isActive, value: group }">
                <div
                  class="message-group-card include-group__item"
                  :class="{
                    'include-group__item--active': isActive,
                    'message-group-card--disabled': !group.listees_count || item.included,
                  }"
                >
                  <div class="message-group-card__icon-wrapper">
                    <CIcon
                      name="unselect"
                      path="messages"
                      class="message-group-card__icon"
                      v-if="isActive"
                    />
                    <CIcon
                      name="circle"
                      path="messages"
                      class="message-group-card__icon"
                      v-else
                    />
                  </div>

                  <div class="message-group-card__description">
                    <span
                      class="message-group-card__font message-group-card__font--name"
                    >
                      {{ group.title }}
                    </span>

                    <span
                      class="message-group-card__font message-group-card__font--users"
                    >
                      {{
                        $tc(
                          "general.massMessage.usersN",
                          group.listees_count || 0,
                          {
                            amount: group.listees_count || 0,
                          }
                        )
                      }}
                    </span>
                  </div>
                </div>
              </template>
            </CRadio>
          </div>

          <div class="mass-messages__title mass-messages__title--second">
            <span class="mass-messages__font mass-messages__font--title">
              {{ $t("general.massMessage.addUser") }}
            </span>
          </div>

          <div class="mass-messages__added">
            <div class="mt-1">
              <div
                class="added-users-spoiler mt-1"
                :class="{ 'added-users-spoiler--open': openSpoiler }"
              >
                <div class="added-users-spoiler__header">
                  <AppInfo
                    v-if="addedUsersList.start && !addedUsersList.start.length"
                    text="No added users"
                  />

                  <UiBadge
                    v-else
                    size="medium"
                    :key="user.id"
                    role="button"
                    :theme="user.isInclude ? 'third' : 'danger'"
                    v-for="user in addedUsersList.start"
                    @click.native="unset(user.id)"
                  >
                    {{ user.name }}
                    <CIcon
                      :name="user.isInclude ? 'check' : 'x'"
                      path="messages"
                      class="added-users-spoiler__icon"
                    />
                  </UiBadge>
                </div>

                <button
                  v-if="addedUsersList.end && addedUsersList.end.length"
                  type="button"
                  v-b-toggle:addedUsers
                  class="added-users-spoiler__button added-users-spoiler__button--arrow"
                  :class="{
                    'added-users-spoiler__button--arrow--open': openSpoiler,
                  }"
                >
                  <CIcon
                    name="arrow-to-top"
                    path="profile"
                    class="added-users-spoiler__icon"
                  />
                </button>
              </div>

              <b-collapse id="addedUsers" v-model="openSpoiler">
                <div class="added-users-spoiler__content">
                  <UiBadge
                    size="medium"
                    :key="user.id"
                    role="button"
                    :theme="user.isInclude ? 'third' : 'danger'"
                    v-for="user in addedUsersList.end"
                    @click.native="unset(user.id)"
                  >
                    {{ user.name }}
                    <CIcon
                      :name="user.isInclude ? 'check' : 'x'"
                      path="messages"
                      class="added-users-spoiler__icon"
                    />
                  </UiBadge>
                </div>
              </b-collapse>
            </div>

            <div class="mt-3">
              <div>
                <div class="mass-messages__field">
                  <CInput
                    type="text"
                    name="searched-users"
                    v-model.trim="searchedUser"
                    :custom-errors="errors"
                    :custom-field-name="$t('general.massMessage.placeholder')"
                    theme="settings"
                    class="new-card__field new-card__field--name"
                    :label="$t('general.massMessage.placeholder')"
                    :rules="'required'"
                    @input="search"
                  />
                </div>

                <div class="mass-messages__search-content" v-if="searchedUser">
                  <div class="mass-messages__info">
                    <AppInfo
                      text="Only followers, subscribers, and expired subscribers can be added"
                    />
                  </div>

                  <div
                    class="user-select"
                    v-for="user in transFormUsers"
                    :key="user.id"
                  >
                    <div class="user-select__left">
                      <div class="user-select__avatar">
                        <img
                          :src="user.avatar || '/mock/PFP.png'"
                          :alt="`user ${user.usernam}`"
                          class="user-select__image"
                        />
                      </div>

                      <div class="user-select__name">
                        <span
                          class="user-select__font"
                          :class="{
                            [`user-select__font--${user.userStatusType}`]:
                              user.userStatusType,
                          }"
                        >
                          {{ user.username }}

                          <CIcon
                            v-if="
                              !user.userStatusButtons &&
                              user.userStatusType === 'include'
                            "
                            name="check"
                            path="messages"
                            class="user-select__icon"
                          />

                          <CIcon
                            v-if="
                              !user.userStatusButtons &&
                              user.userStatusType === 'exclude'
                            "
                            name="x"
                            path="messages"
                            class="user-select__icon user-select__icon--x"
                          />
                        </span>
                      </div>
                    </div>

                    <div class="user-select__right">
                      <div class="user-select__select">
                        <UiBadge
                          v-if="user.userStatusButtons"
                          :key="user.id"
                          theme="third"
                          role="button"
                          @click.native="include(user.id)"
                        >
                          {{ $t("general.massMessage.include") }}
                          <CIcon
                            name="check"
                            path="messages"
                            class="user-select__icon"
                          />
                        </UiBadge>
                      </div>

                      <div class="user-select__select">
                        <UiBadge
                          v-if="user.userStatusButtons"
                          :key="user.id"
                          theme="danger"
                          role="button"
                          @click.native="exclude(user.id)"
                        >
                          {{ $t("general.massMessage.excludeButton") }}
                          <CIcon
                            name="x"
                            path="messages"
                            class="user-select__icon user-select__icon--x"
                          />
                        </UiBadge>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CRadio from "@/features/ui/CRadio.vue";
import UiBadge from "@/features/ui/common/UiBadge.vue";
import CInput from "@/features/ui/СInput.vue";
import AppInfo from "@/features/ui/common/AppInfo.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { debounce } from "lodash";
import { FormStorageService } from "@/services/FormStorageService";
import { massMessage } from "@/config/messages";
import User from "@/components/models/User";
export default {
  name: "MassMessages",
  components: {
    AppInfo,
    CInput,
    UiBadge,
    CRadio,
    CIcon,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      form: {
        include: [],
        exclude: [],
        include_users: [],
        exclude_users: [],
      },
      included: [],
      openSpoiler: false,
      searchedUser: "",
      users: [],
    };
  },
  watch: {
    form: {
      immediate: false,
      deep: true,
      handler(formData) {
        FormStorageService.saveForm(massMessage.form, formData);
      },
    },

    users: {
      immediate: false,
      deep: true,
      handler(usersList) {
        FormStorageService.saveForm(massMessage.users, usersList);
      },
    },
  },
  computed: {
    ...mapGetters({
      messagesList: "lists/messagesList",
      searchedUsers: "messages/searchedUsers",
      user: "user",
    }),
    isCreatorRole() {
      return this.user.role.includes(User.ROLE_CREATOR);
    },
    transformedMessagesList() {
      return this.messagesList.map(item => {
        const included = this.form.include.some(includedItem => includedItem.id === item.id)
        const excluded = this.form.exclude.some(excludeItem => excludeItem.id === item.id)
        return {
          ...item,
          disabled: !item.listees_count,
          included,
          excluded,
        }
      })
    },

    unlessSelect() {
      return !Object.values(this.form).some(group => group.length) && !this.users.length
    },

    transFormUsers() {
      return this.searchedUsers.map((user) => {
        // if user already have status
        const addedUser = this.users?.find((item) => item.id === user.id);
        if (addedUser) return addedUser;

        return {
          ...user,
          name: user.username,
          isInclude: user.userStatusType === "include",
          userStatusType: "",
          userStatusButtons: true,
          theme: "third",
        };
      });
    },

    addedUsersList() {
      const usersList = this.users
        .filter((user) => {
          return !user.userStatusButtons;
        })
        .map((item) => {
          return {
            ...item,
            name: item.username,
            isInclude: item.userStatusType === "include",
          };
        });

      const start = usersList.slice(0, 3);
      const end = usersList.slice(3);

      return {
        start,
        end,
      };
    },
  },

  mounted() {
    if(!this.isCreatorRole) {
      this.$router.push('/404')
    }
    this.initState();
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      fetchMessagesList: "lists/fetchMessagesList",
      fetchSearchedUsers: "messages/fetchSearchedUsers",
    }),
    ...mapMutations({
      setMassMessageData: "messages/setMassMessageData",
    }),

    initState() {
      this.initFormData();

      this.waitRequest(() => {
        return this.fetchMessagesList().catch(this.checkErrors);
      });
    },

    initFormData() {
      const savedData = FormStorageService.loadForm(massMessage.form);
      if (savedData) {
        this.form = savedData;
      }

      const savedUsers = FormStorageService.loadForm(massMessage.users);
      if (savedUsers) {
        this.users = savedUsers;
      }
    },

    search: debounce(function (query) {
      this.waitRequest(() => {
        this.$showSpinner();
        return this.fetchSearchedUsers({ searchString: query }).catch(
          this.checkErrors
        );
      });
    }, 300),

    toNext() {
      this.prepareData();
      this.$router.push("/mass-messages/message");
    },
    include(userId) {
      console.log("include", userId);
      this.setStatus(userId, {
        userStatusType: "include",
        userStatusButtons: false,
      });
    },
    exclude(userId) {
      this.setStatus(userId, {
        userStatusType: "exclude",
        userStatusButtons: false,
      });
    },
    unset(userId) {
      this.users = this.users.filter((user) => user.id !== userId);
    },
    setStatus(
      userId,
      options = { userStatusType: "include", userStatusButtons: false }
    ) {
      const userToList = this.transFormUsers.find((user) => user.id === userId);
      if (userToList) {
        this.users.push({ ...userToList, ...options });
      }
    },

    prepareData() {
      let massMessageData = {};
      Object.entries(this.form).forEach(([key, value]) => {
        massMessageData[key] = value.map((item) => item.id);
      });
      const users = Object.groupBy(this.users, (user) =>
        user.userStatusType === "include" ? "include_users" : "exclude_users"
      );

      Object.entries(users).forEach(([key, value]) => {
        massMessageData[key] = value.map((item) => item.id);
      });

      this.setMassMessageData(massMessageData);
    },
  },
};
</script>

<style lang="scss" scoped>
.mass-messages {
  &__top {
    margin-top: 18px;
  }

  &__title {
    &--second {
      margin-top: 24px;
    }
  }

  &__search-content {
    border-bottom: 1.5px solid $app-gray-25;
    border-left: 1.5px solid $app-gray-25;
    border-right: 1.5px solid $app-gray-25;
    border-radius: 0 0 5px 5px;
    position: relative;
    top: -24px;
  }

  &__info {
    padding: 15px 15px 12px;
  }

  &__scroll-group {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 10px;
    overflow: scroll;

    @include hideScroll;
  }

  &__font {
    &--title {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      color: $app-gray-41;
    }
  }
}

.include-group {
  text-align: center;

  &__item {
    padding: 8px 8px 10px;
    border-radius: 5px;
    background-color: $app-gray-7;

    &--active {
      //background-color: $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--active {
      color: $white;
    }
  }
}

.message-group-card {
  $parent: &;

  display: flex;
  align-items: center;
  padding: 8px 13px;
  margin-right: 8px;
  width: max-content;
  border-radius: 5px;
  background: $app-gray-39;

  &__description {
    display: flex;
    flex-direction: column;
  }

  &__icon-wrapper {
    margin-right: 8px;
  }

  &__font {
    font-family: var(--font-family);

    &--name {
      font-weight: 500;
      font-size: 14px;
      color: $black;
    }

    &--users {
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: $app-gray-40;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
  }

  &--disabled {
    opacity: 0.5;

    #{$parent}__font {
      pointer-events: none;
      filter: grayscale(0.5);
      color: $app-gray-5;
    }
  }
}

.added-users-spoiler {
  display: flex;
  justify-content: space-between;

  &__header {
    display: flex;
    flex: 1;
    overflow: hidden;
    gap: 6px;
  }

  &__content {
    margin-top: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  &__icon {
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;

    &--x {
      width: 8px;
      height: 8px;
      min-width: 8px;
      min-height: 8px;
    }
  }

  &__button {
    display: inline-block;
    width: auto;

    &--arrow {
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}

.user-select {
  display: flex;
  justify-content: space-between;
  padding: 9px 15px;

  &:not(:first-of-type) {
    padding-top: 0;
  }

  &__left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__avatar {
    margin-right: 7px;
  }

  &__image {
    width: 27px;
    height: 27px;
    min-width: 27px;
    min-height: 27px;
    overflow: hidden;
    border-radius: 50%;
  }

  &__name {
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    color: #acacac;

    &--exclude {
      color: #ff6e6e;
    }

    &--include {
      color: #55a1ff;
    }
  }

  &__right {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__select {
  }

  &__icon {
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;

    &--x {
      width: 8px;
      height: 8px;
      min-width: 8px;
      min-height: 8px;
    }
  }
}
</style>
